import React from 'react';
import PropTypes from 'prop-types'
import AjaxClient from '../../ajax/AjaxClient.js'
import { BASE_URL } from '../../constants.js'

const touchImage = require('../../images/touch-white.png');

export const PlayScreenState = Object.freeze({
    REQUEST_FILE_INFO: Symbol("request file info"),
    FETCHING_FILE_INFO: Symbol("fetching file info"),
    PLAYING: Symbol("playing")
});

class PlayScreen extends React.Component {
    static propTypes = {
        playingFinished: PropTypes.func.isRequired,
        audioPlayer: PropTypes.object.isRequired,
        userInput: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.processJSONResponse = this.processJSONResponse.bind(this);

        this.initialState = {
            audioFileURL: undefined,
            imageURL: undefined,
            directPlayURL: '',
            directPlayShortURL: '',
            playlist: [],
            playState: PlayScreenState.REQUEST_FILE_INFO
        };
        this.playingFinished = this.playingFinished.bind(this);
        this.stopPlaying = this.stopPlaying.bind(this);
        this.props.audioPlayer.onPlayingEnded = this.playingFinished;
        this.state = this.initialState;
        this.cache = {};
        console.debug('PlayScreen: constructed. Props: audio ' + this.props.audioURL + ' image ' + this.props.imageURL);

    }

    createAjaxClient() {
        return new AjaxClient();
    }

    stopPlaying() {
        console.debug('PlayScreen: stop requested');
        this.props.audioPlayer.stop();
        this.playingFinished();
    }

    playingFinished() {
        console.debug('PlayScreen: playingFinished');
        this.playNext(this.state.playlist);
    }

    processJSONResponse(responseObject) {
        // ([{"url":"\/uploads\/audio\/et 42.mp3","imageUrl":"\/uploads\/audio\/et.jpg","label":"et 42","id":198}])
        console.debug(`PlayScreen: playing ${responseObject.url}`);
        this.playNext(responseObject);
    }

    playNext(playlist) {
        if (playlist.length > 0) {
            const responseObject = playlist.shift();
            let comma = playlist.length > 0 ? ',' : '';
            this.setState({
                ...this.state,
                playState: PlayScreenState.PLAYING,
                playlist: playlist,
                audioFileURL: BASE_URL + responseObject.url,
                imageURL: BASE_URL + responseObject.imageUrl,
                directPlayURL: this.state.directPlayURL + responseObject.label + comma,
                directPlayShortURL: this.state.directPlayShortURL + responseObject.id + comma
            }
            );
        } else {
            this.props.playingFinished(
                this.state.directPlayURL,
                this.state.directPlayShortURL);
        }


    }

    componentWillUnmount() {
        this.setState(this.initialState);
    }

    componentDidMount() {
        let log = 'PlayScreen: componentDidUpdate ';

        if (this.state.playState === PlayScreenState.REQUEST_FILE_INFO) {
            const input = this.props.userInput;
            log += ', init call for ' + input;
            if (this.cache[input] !== undefined) {
                log += 'PlayScreen: Using cached data';
                this.processJSONResponse(this.cache[input]);
            } else {
                this.createAjaxClient().getObject(BASE_URL + '/api/file-info?query=' + encodeURIComponent(input),
                    (info) => {
                        console.debug('PlayScreen: Info:', info);
                        if (info != null && info.length > 0) {
                            this.cache[input] = info;
                            this.processJSONResponse(info);
                        } else {
                            console.debug('PlayScreen: Nothing found for info:', info);
                            this.playingFinished();
                        }
                    });
                this.setState({ ...this.state, playState: PlayScreenState.FETCHING_FILE_INFO });
            }
        }
        console.debug(log);
    }

    render() {
        console.log('PlayScreen: AudioPlayer initialized?', this.props.audioPlayer.initialized);
        if (!this.props.audioPlayer.initialized) {
            return <TouchToStart clickListener={() => { this.props.audioPlayer.init(); this.setState({ ...this.state }); }}/>
        } else {
            this.props.audioPlayer.play(this.state.audioFileURL);
            return <SymbolPhoto imageURL={this.state.imageURL} clickListener={this.stopPlaying} />
        }
    }


}
function SymbolPhoto ({clickListener, imageURL}) {
    return <FullScreen clickListener={clickListener} >
                {(imageURL !== undefined) ?
                    <img key='2' src={imageURL} className='centered-image' alt='Symbolfoto' />
                    :
                    <div key='3' className="loader">Loading...</div>
                }
            </FullScreen>
}

function TouchToStart(props) {
    return <FullScreen {...props}>
                <div key='1' className='centered-image'>
                    <figure>
                        <img key='2' src={touchImage} alt='Hier berühren' />
                        <figcaption>Touch here to start</figcaption>
                    </figure>
                </div>
            </FullScreen>
}

function FullScreen(props) {
    return <div className='fullscreen' onClick={props.clickListener}>
        {props.children}
    </div>
}

export default PlayScreen;
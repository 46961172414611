
class AudioPlayer {

    constructor(playingFinished) {
        if (!playingFinished) {
            playingFinished = () => { };
        }
        this.onPlayingEnded = playingFinished;
        this.play = this.play.bind(this);
        this.audioPlayer = document.createElement("audio");
        // the following is a guard, it needs to be as atomic as it gets, which is why it cannot
        // be stored in the react state.
        this.playing = false;
        this.audioPlayer.addEventListener("ended", () => {
            this.ended();
        });
        this.initialized = false;

    }
    init() {
        if (!this.initialized) {
            console.log('AudioPlayer: initializing...');
            // workaround: The first call to play is - for some reason - blocked by safari
            // so we play a non existent sound (which silently fails) , and then 
            // the subsequent calls succeed!
            //
            // this.audioPlayer.play('nothing.mp3');
            this.audioPlayer.play('data:audio/mpeg;base64,/+MYxAAAAANIAUAAAASEEB/jwOFM/0MM/90b/+RhST//w4NFwOjf///PZu////9lns5GFDv//l9GlUIEEIAAAgIg8Ir/JGq3/+MYxDsLIj5QMYcoAP0dv9HIjUcH//yYSg+CIbkGP//8w0bLVjUP///3Z0x5QCAv/yLjwtGKTEFNRTMuOTeqqqqqqqqqqqqq/+MYxEkNmdJkUYc4AKqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq');
            this.initialized = true;
        }
    }
    ended() {
        if (this.playing) {
            console.debug('AudioPlayer: ended ' + this.audioPlayer.playing);
            this.stop();
            this.onPlayingEnded();
        } else {
            console.debug('AudioPlayer: ended, but not playing  ' + this.audioPlayer.playing);
        }

    }
    stop() {
        this.playing = undefined;
        this.audioPlayer.pause();
    }
    play(file) {
        console.log('AudioPlayer: Playing file:', file);
        if (file !== undefined && this.playing !== file) {
            clearTimeout(this.workaroundTimer);
            this.audioPlayer.src = file;
            const promise = this.audioPlayer.play();
            if (promise !== undefined) {
                promise.catch(error => {
                    console.error('AudioPlayer: Couldnt start playing: ' + error);
                }).then(() => {
                    this.playing = file;
                });
            }
        }
    }
}

export default AudioPlayer;
import React from 'react';
import SuggestionBox from './input/SuggestionBox.js'
import PlayScreen from './play/PlayScreen.js'
import AudioPlayer from './audio/Player.js'

export const PlayState = Object.freeze({
    IDLE: Symbol("idle"),
    PLAYING: Symbol("playing")
});

class Parkbank extends React.Component {

    constructor() {
        super();

        this.playingEnded = this.playingEnded.bind(this);
        this.state = {
            userInput: undefined,
            playState: PlayState.IDLE
        };
        this.audioPlayer = new AudioPlayer();

    }
    componentDidMount() {
        const urlInput = this.getURLParam();
        if (urlInput !== null) {
            this.processInput(urlInput.replace('name:', ''));
        } else {
            console.debug('Parkbank: no request found in URL.');
        }
    }

    getURLParam() {
        if (window.location.search.length > 1) {
            return window.location.search.slice(1);
        } else {
            return null;
        }
    }

    tryToPlay(newValue) {
        console.debug(`Parkbank: User input changed from ${this.state.userInput} to ${newValue}`);
        this.setState({
            ...this.state, userInput: newValue, state: PlayState.IDLE
        });
    }

    playingEnded(names, ids) {
        console.debug('Parkbank: playing finished');
        const baseURL = window.location.origin + window.location.pathname;
        const directPlayURL = encodeURI(baseURL + '?name:' + names);
        const directPlayShortURL = encodeURI(baseURL + '?id:' + ids);
        this.setState({
            ...this.state, playState: PlayState.IDLE, directPlayURL: directPlayURL,
            directPlayShortURL: directPlayShortURL
        });
        window.history.pushState("pbp", "Parkbank played", window.location.pathname + encodeURI('?id:' + ids));
    }

    processInput(input) {
        console.debug('Parkbank: processing input ' + input);
        this.setState({ ...this.state, userInput: input, playState: PlayState.PLAYING });
    }

    render() {
        console.debug('Parkbank: rendering, state ' + this.state.playState.toString());
        let playScreen;
        if (
            this.state.playState === PlayState.PLAYING
        ) {
            console.debug('Parkbank: creating playScreen');
            playScreen = <PlayScreen 
                audioPlayer={this.audioPlayer}
                userInput={this.state.userInput}
                playingFinished={(directPlayURL, directPlayShortURL) => { this.playingEnded(directPlayURL, directPlayShortURL); }}
            />
        } else {
            console.debug('Parkbank: no PlayScreen created');
        }
        const buttonHandler = () => {
            // We can initialize the Player only after a user interaction. An input is an interaction
            // so we initialize it here.
            this.audioPlayer.init();
            this.processInput(this.state.userInput);
        };
        return (
            <div className="app" >
                {playScreen}
                <div className="parkbank-control">
                    <div className="parkbank-input">
                        <SuggestionBox valueChanged={(newValue) => {
                            console.debug('Parkbank: SuggestionBox Callback');
                            // We can initialize the Player only after a user interaction. An input is an interaction
                            // so we initialize it here.
                            this.audioPlayer.init();
                            this.processInput(newValue);
                        }}
                            inputChanged={(input) => {
                                console.debug('Parkbank: inputChangedCallback');
                                this.tryToPlay(input);
                            }}
                        />
                        <button type='button' onClick={buttonHandler}>Play</button>
                    </div>
                    <div className='info-link' style={{display: this.state.directPlayURL ? 'block' : 'none'}}>
                        <a target='_blank' rel='noopener noreferrer' href={this.state.directPlayURL}>{this.state.directPlayURL}</a>
                    </div>
                    <div className='info-link' style={{display: this.state.directPlayShortURL ? 'block' : 'none'}}>
                        <a target='_blank' rel='noopener noreferrer' href={this.state.directPlayShortURL}>{this.state.directPlayShortURL}</a>
                    </div>
                </div>
            </div>
        );
    }

}

export default Parkbank;
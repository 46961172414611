import React from 'react';

import Autosuggest from 'react-autosuggest';
import AjaxClient from '../../ajax/AjaxClient.js'
import PropTypes from 'prop-types'
import { BASE_URL } from '../../constants.js'

function getSuggestionValue(suggestion) {
    return suggestion;
}

function renderSuggestion(suggestion) {
    return (
        <span>{suggestion}</span>
    );
}

class SuggestionBox extends React.Component {
    static propTypes = {
        valueChanged: PropTypes.func.isRequired,
        inputChanged: PropTypes.func.isRequired,
    }

    constructor() {
        super();
        this.lastCall = 0;
        this.lastReceivedCall = -1;
        this.state = {
            value: '',
            suggestions: [],
            blockSuggestions: false
        };
        this.storeInputReference = this.storeInputReference.bind(this);
        const setState = this.setState.bind(this);
        
        this.onSuggestionSelected = (a,b) => { this.handleSuggestionSelected(a,b, setState); };
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onChange = this.onChange.bind(this);
        
    }

    onChange(event, { newValue, method }) {
        console.log(`SuggestionBox: onChange: newValue is ${newValue} method: ${method}`)
        this.setState({
            suggestions: this.state.suggestions,
            value: newValue,
            blockSuggestions: false
        });
        this.props.inputChanged(newValue);
    };

    handleSuggestionSelected (suggestion, suggestionEvent, setState) {
        console.log(`SuggestionBox: onSuggestionSelected: newValue is: ${suggestionEvent.suggestion}`);
        setState({...this.state, blockSuggestions: true, suggestions: []});
        if (suggestionEvent.method !== 'enter') {
            this.props.valueChanged(suggestionEvent.suggestion);
        } else {
            console.log('SuggestionBox: discarding enter event')
        }
    };

    onSuggestionsFetchRequested({ value }) {
        if (value === undefined || value.trim() === '') {
            this.setState({ ...this.state, suggestions: [] });
        } else {
            this.lastCall++;
            const thisCall = this.lastCall;
            console.log(`SuggestionBox: initializing call for ${value} with id ${thisCall}`);
            new AjaxClient().getObject(BASE_URL + '/api/hint?query=' + encodeURIComponent(value),
                (suggestions) => {
                    let newSuggestions;
                    
                    if (thisCall < this.lastReceivedCall) {
                        console.log(`SuggestionBox: disacarding answer of ${thisCall} because lastReceivedCall was ${this.lastReceivedCall}`);
                        return;
                    } else if (this.state.blockSuggestions) {
                        console.log('SuggestionBox: discarding answer. Suggestions are blocked');
                        suggestions = [];   
                    } 
                    this.lastReceivedCall = thisCall;
                    
                    if (suggestions !== undefined) {
                        newSuggestions = suggestions;
                    } else {
                        newSuggestions = [];
                    }
                    this.setState({
                        ...this.state,
                        suggestions: newSuggestions
                    });
                }
            );
        }

    };

    onSuggestionsClearRequested () {
        this.setState({
            suggestions: []
        });
    };

    storeInputReference(autosuggest) {
        if (autosuggest !== null) {
          this.input = autosuggest.input;
          this.input.onkeyup = (e) => {
              if (e.keyCode === 13) {
                console.log('SuggestionBox: State in storeInputRef-callback: ', this.state);
                this.props.valueChanged(this.state.value);
              }
          
            }
            this.input.onkeyup = this.input.onkeyup.bind(this);
        }
      }

    render() {
        const { id, placeholder } = this.props;
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder,
            value,
            onChange: this.onChange,
            autoComplete: "off",
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: "false"
        };

        return (
            <Autosuggest
                id={id}
                ref={this.storeInputReference}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                focusInputOnSuggestionClick={false}
                onSuggestionSelected={this.onSuggestionSelected}
                
            />
        );
    }
}


export default SuggestionBox;
import React, { Component } from 'react';
import Parkbank from './components/Parkbank.js'
import './style/App.scss';

class App extends Component {
  render() {
    return <Parkbank />
  }    
}  

export default App;

export default class AjaxClient {

    getResponseFromHeader(uri) {
        console.log('AjaxClient: getResponseFromHeader');
        return new Promise(resolve => {
        // for some reason the Json header cannot be read with fetch. It can however be read with XMLHttpRequest.
        const request = new XMLHttpRequest();
        request.open('GET', uri);
        request.onreadystatechange = () => {
            resolve(request.getResponseHeader('X-Json'));
        };
        request.send();
        });
    }

    getObject(uri, callback) {
        console.log('AjaxClient getObject:', uri);
        fetch(uri).then((response) => {
            const promise = response.json();
            promise.then(callback);
        });
    }
}